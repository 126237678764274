.badge {
  display: inline-block;
  padding: .25rem .625rem;
  font-size: 83%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
}

.badge-pill {
  padding-right: .65em;
  padding-left: .65em;
  border-radius: 10rem;
}

.badge-success {
  color: #16c333;
  background-color: #dff8e4;
}

.badge {
  margin: .25rem 0;
}

.badge-pill {
  min-width: 60px!important;
}

.badge-warning {
  color: #b36800;
  background-color: #ffdfb3;
}
