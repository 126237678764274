.modal-content {
  border: 0;
  border-radius: 0;
  background: white;

  .modal-header {
    padding: 1.5rem 2rem;
    padding-bottom: 1rem;
    border-bottom: 0px;

    .modal-title {
      font-size: 1.125rem;
      font-weight: 600;
    }

    .icon {
      cursor: pointer;
    }
  }

  .modal-body {
    padding: 2rem !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    /* 100% = dialog height, 240px = header + footer */
    max-height: calc(100vh - 240px);
    font-size: 1rem;
    font-weight: 400;
  }

  .modal-footer {
    padding: 1rem 2rem;
    border: none;
  }
}
