.card-header {
  padding: 1.25rem 1.5rem;
  margin-bottom: 0;
  color: inherit;
  background-color: transparent;
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.08);
}

.card-footer {
  padding: 1.25rem 1.5rem;
  background-color: transparent;
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.08);
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  display: inline-block;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
