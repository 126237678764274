.form-group {
  margin-bottom: 1rem;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-control {
  display: block;
  width: 100%;
  height: 2.875rem;
  padding: 0.625rem 1.1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: all 0.2s ease-in-out;
}

.input-group-transparent .form-control {
  box-shadow: none;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: rgba(0, 79, 110, 0.5);
  outline: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 20px rgb(0 79 110 / 10%);
}

.form-control-label {
  color: #343a40;
  font-size: 0.875rem;
  font-weight: 600;
}

.label {
  margin-bottom: 0.5rem;
}

.input-group-append,
.input-group-prepend {
  display: flex;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.625rem 1.1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #adb5bd;
  text-align: center;
  white-space: nowrap;
  background-color: white;
  border: 0.0625rem solid #ced4da;
  border-radius: 0.25rem;
}
