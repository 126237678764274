// confirmation
.accordion-button:focus {
  text-decoration: none;
  box-shadow: none;
  box-shadow: none;
  background-color: transparent !important;
}

.accordion-button:focus {
  text-decoration: none;
  box-shadow: none;
  box-shadow: none;
  background-color: transparent !important;
}

// accordions
.accordion {
  .accordion-item {
    position: relative;
    border: 0;
    background: transparent;
    border-top: 1px solid var(--primary-800);
    border-radius: 0;

    &:last-child {
      border-bottom: 1px solid var(--primary-800);
    }
  }

  .accordion-button {
    transition: all 0.2s;
    position: relative;
    background-color: transparent;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 2.5rem;
    padding-left: 0;
    box-shadow: none;
    color: inherit;

    &:not(.collapsed) {
      color: inherit;
    }

    .row {
      flex: 1 1 auto;
    }

    &::after {
      position: absolute;
      right: 0;
      font-size: 1rem;
      font-family: 'Font Awesome 6 Sharp';
      content: '\f078';
      color: inherit;
      width: 1em;
      background: transparent;
    }
  }

  .accordion-body {
    padding: 0 2.5rem 2rem 0;
  }
}

// steps
.accordion-steps {
  .accordion-item {
    position: relative;
    counter-increment: accordion;

    &:not(:first-child) {
      border-top: 0;
      margin-top: 2.5rem;
    }

    &:not(:first-child) {
      &::before {
        content: '';
        content: '';
        position: absolute;
        width: 0.125rem;
        top: -2rem;
        left: 1.875rem;
        bottom: calc(50% + 40px);
        background: #ccc;
      }
    }

    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        width: 0.125rem;
        top: calc(50% + 40px);
        left: 1.875rem;
        bottom: -2rem;
        background: #ccc;
      }
    }
  }

  .accordion-button {
    position: static;
    min-height: 4rem;

    &::before {
      transition: all 0.2s ease-in-out;
      position: absolute;
      top: 50%;
      left: 0;
      content: counter(accordion, decimal);
      display: flex;
      width: 4rem;
      height: 4rem;
      align-items: center;
      justify-content: center;
      margin-top: -2rem;
      background: #000;
      color: #fff;
      font-size: 1.125rem;
      border-radius: 50%;
      z-index: 4;
    }

    &::after {
      position: static;
      margin-left: 0.65rem;
      margin-top: 0;
    }

    &[aria-expanded='true'] {
      color: var(--bs-primary);

      &::before {
        background: var(--bs-primary);
      }
    }

    &:hover {
      color: var(--bs-primary);

      &::before {
        background: var(--bs-primary);
      }
    }
  }

  .accordion-button,
  .accordion-body {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 6rem;
  }

  .accordion-body {
    padding-bottom: 1rem;
  }
}

// confirmation
.confirmation {
  .accordion-item {
    padding: 0 2rem;

    &:first-child {
      border-top: none;
      border-bottom: none;
    }

    &:not(:first-child) {
      border-top: 1px solid var(--primary-400);
      border-bottom: none;
    }
  }
}

// highlight
.accordion[class*='-highlight'] {
  &:hover {
    .accordion-button {
      opacity: 0.5;
    }
  }

  .accordion-button {
    &:hover,
    &:not(.collapsed) {
      opacity: 1;
    }
  }
}
