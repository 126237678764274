.mr-2,
.mx-2 {
  margin-right: 0.5rem;
}

.bg-lighter {
  background-color: var(--secondary-400) !important
}

.icon-lg i,
.icon-lg svg {
  font-size: 1.75rem;
}

.icon-shape {
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
}

.icon {
  display: inline-flex;
  min-width: 3rem;
  min-height: 3rem;
}

.icon-lg {
  min-width: 4rem;
  min-height: 4rem;
}

.text-success {
  color: #4cd964 !important;
}