.ng-select.ng-select-single .ng-select-container {
  height: 2.875rem;
}

.ng-select .ng-select-container {
  color: #333;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  min-height: 36px;
  align-items: center;
}

.ng-select .ng-select-container {
  min-height: 47px;
  border-radius: 0.25rem;
}

.ng-select .ng-select-container {
  cursor: default;
  display: flex;
  outline: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.ng-select {
  padding-bottom: 0 !important;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
  padding-left: 8px !important;
}

.ng-dropdown-panel {
  bottom: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-color: #e6e6e6;
  margin-bottom: -1px;
  border: 1px solid #ccc;
  background-color: white;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 10px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #333;
  background-color: #ebf5ff;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #f5faff;
  color: #333;
}

.ng-select .ng-select-container {
  min-height: 47px;
  border-radius: 0.25rem;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 13px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 13px;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}

.ng-select .ng-arrow-wrapper {
  width: 25px;
  padding-right: 5px;
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
  top: -2px;
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.ng-select .ng-select-container .ng-value-container {
  padding-left: 10px;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #adb5cc;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  pointer-events: none;
  display: inline-block;
  height: 0;
  width: 0;
  position: relative;
}
