:root {
  --primary-900: #000000;
  --primary-800: #292c2e;
  --primary-700: #52575b;
  --primary-600: #7a8389;
  --primary-500: #a3aeb6;
  --primary-400: #ccdae4;
  --primary-300: #d6e1e9;
  --primary-200: #e0e9ef;
  --primary-100: #ebf0f4;
  --primary-50: #f9f9f9;

  --secondary-900: #14190c;
  --secondary-800: #293318;
  --secondary-700: #516630;
  --secondary-600: #7a9947;
  --secondary-500: #a2cc5f;
  --secondary-400: #cbff77;
  --secondary-300: #d5ff92;
  --secondary-200: #e0ffad;
  --secondary-100: #eaffc9;
  --secondary-50: #f5ffe4;

  --neutral-800: #333333;
  --neutral-700: #4d4d4d;
  --neutral-600: #666666;
  --neutral-500: #808080;
  --neutral-400: #999999;
  --neutral-300: #b2b2b2;
  --neutral-200: #cccccc;
  --neutral-100: #e5e5e5;
  --neutral-50: #f0f0f0;

  --success: #00b67a;
  --danger: #ff4d35;
  --danger-10: #ff4d3519;
  --pending: #fa8e52;
  --warning: #f9f270;
  --info: #739bf4;
  --deleted: #000000;

  --drop-shadow: box-shadow: 0px 1px 8px rgba(16, 24, 40, 0.06), 0px 1px 10px rgba(16, 24, 40, 0.05);
  --hover-shadow: box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.25);
  --blur-1: backdrop-filter: blur(4px);
  --blur-2: backdrop-filter: blur(8px);
  --blur-3: backdrop-filter: blur(12px);
}

$black: black;
$theme-colors: (
  primary: $black
);

.body-6 {
  font-size: 0.875rem;
  font-weight: 400;
}

.button-4 {
  font-size: 0.875rem;
  font-weight: 500;
}


@import '../../../node_modules/bootstrap';
