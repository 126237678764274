.custom-control-input.is-valid .custom-control-label,
.was-validated .custom-control-input:invalid .custom-control-label,
.was-validated .custom-control-input:valid .custom-control-label {
  color: #666 !important;
}

.custom-control-label {
  display: block;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-radio .custom-control-label:before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #004f6e;
  background-color: #004f6e;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked .custom-control-label:before {
  color: #fff;
  border-color: #004f6e;
  background-color: #004f6e;
  box-shadow: none;
}

.custom-radio .custom-control-input .custom-control-label:before {
  border-color: #004f6e;
  background-color: #fff;
}

.custom-control-label:before {
  border: 0.0625rem solid #ced4da;
  transition: all 0.2s ease-in-out;
}

.custom-control-label:before {
  position: absolute;
  top: 0.125rem;
  left: -3rem;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  content: "";
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-control-label:after {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 50% 50%;
}

.custom-control-label:after,
.custom-control-label:before {
  content: "";
  position: absolute;
  top: 0.125rem;
  left: -3rem;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
}

.custom-control-label:before {
  pointer-events: none;
  background-color: #fff;
  box-shadow: none;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 3rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

// custom checkbox
.custom-control-label:after,
.custom-control-label:before {
  position: absolute;
  top: 0.125rem;
  left: -3rem;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  content: "";
}

.custom-control-label:before {
  border: 0.0625rem solid #ced4da;
  transition: all 0.2s ease-in-out;
}

.custom-checkbox .custom-control-label:before {
  border-radius: 0.2rem;
}

.custom-control-input:checked .custom-control-label:before {
  color: #fff;
  border-color: #004f6e;
  background-color: #76e288;
  box-shadow: none;
}

.custom-control-input.is-valid .custom-control-label:before,
.was-validated .custom-control-input:valid .custom-control-label:before {
  background-color: #b5efbf;
  border-color: #b5efbf;
}

.custom-control-input.ng-valid ~ .custom-control-label:before {
  background-color: #76e288;
  border-color: #b5efbf;
}

.custom-radio .custom-control-input ~ .custom-control-label:before {
  border-color: #004f6e;
  background-color: white;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before,
.custom-radio .custom-control-input ~ .custom-control-label:after {
  border-color: #004f6e;
}

// check box success background
.custom-checkbox .custom-control-input:checked .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input ~ .custom-control-label, .custom-radio {
  cursor: pointer;
}
