.dropdown-toggle::after {
  display: none;
}

.dropdown-menu.show {
  left: -80px !important;
}

.invalid-message {
  font-size: 0.9rem !important;
  text-align: left !important;
  margin-top: 0.25rem;
  color: #ff3b30;
}

.bg-gradient-primary {
  background: white !important;
}

.slice {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.slice-lg {
  padding-top: 8rem;
  padding-bottom: 6rem;
}

.ml-lg-auto {
  margin: 0 auto;
}

.btn-animated-x .btn-inner--hidden {
  right: -100%;
  transition: right 0.3s ease 0s;
}

.btn-animated .btn-inner--hidden {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.bg-success {
  background-color: var(--secondary-400) !important;
}