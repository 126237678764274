@import './icons.scss';
@import './theme.scss';
@import './form.scss';
@import './select.scss';
@import './switch.scss';
@import './radio.scss';
@import './card.scss';
@import './badge.scss';
@import './accordion.scss';
@import './application.scss';
@import './modal.scss';
@import './layout.scss';
@import './ng-lib-button.scss';

body {
  color: #6c7686;
  background: white;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  font-family: proxima-nova, Helvetica, Arial, Hiragino Kaku Gothic Pro,
    ヒラギノ角ゴ Pro W3, メイリオ, Meiryo, ＭＳ Ｐゴシック, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1rem;
  line-height: 1.5;
  overflow-x: hidden;
}

// text
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.3;
  color: black;
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
  font-weight: 600;
}

.bg-secondary {
  background-color: #f5f9fb !important;
}

.text-muted {
  color: #adb5bd !important;
}

.text-light {
  color: #ced4da !important;
}

.text-right {
  text-align: right !important;
}

.list-group-mine .list-group-item {
  background-color: $black;
  border-top: 1px solid $black;
  border-left-color: #fff;
  border-right-color: #fff;
  padding: 1rem 1.25rem;
}

.list-group-item {
  padding: 0.5rem 0;
}

.list-group-item.active {
  z-index: 2;
  background-color: $black;
  border-color: $black;
}

.toast-top-center {
  top: 30px;
}

.invalid-feedback {
  font-size: 0.9rem !important;
  text-align: left !important;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.has-success {

  .input-group,
  .input-group:focus,
  .input-group-text,
  .ng-select .ng-select-container,
  .input-group-text,
  .date-mask,
  .country-select {
    border-color: #53d86a !important;
  }
}

.has-error {

  .input-group,
  .input-group:focus,
  .input-group-text,
  .ng-select .ng-select-container,
  .input-group-text,
  .date-mask,
  .form-control,
  .country-select {
    border-color: #fd3d39 !important;
  }
}

.custom-checkbox .custom-control-input~.custom-control-label,
.custom-radio {
  color: var(--primary-900);
}

.disabled {

  &.custom-checkbox .custom-control-input~.custom-control-label,
  &.custom-radio {
    color: var(--neutral-300);
  }
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
  color: #666666 !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.input-group.input-with-buttons {
  border: 0.0625rem solid #ced4da;
  border-radius: 0.25rem;

  .form-control {
    border: 0 solid #ced4da;
  }

  .input-group-text {
    border: 0;
    background-color: #fff;

    .border-left {
      border-left: 0.0625rem solid #ced4da !important;
    }
  }
}

.input-group.input-with-buttons:focus-within {
  border-color: rgba(0, 79, 110, 0.5);
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 20px rgba(0, 79, 110, 0.1);
}

.accordion {
  .btn.btn-link {
    width: 100%;
  }
}

.accordion.list-group.list-group-flush {
  .card {
    border-bottom: 0;
    border-right: 0;
    border-left: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .card-header {
    padding: 0;
    border: 0;
  }

  .card-body {
    padding-top: 0;
  }
}

.accordion.separated {
  .card:last-of-type {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.08);
    border-radius: 0.375rem;
    margin-bottom: 1rem;
  }

  .card:first-of-type {
    border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.08);
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    margin-bottom: 1rem;
  }

  .card-header {
    .btn.btn-link {
      padding: 0;

      div:after {
        content: '-';
      }
    }

    .btn.btn-link.collapsed {
      div:after {
        content: '+';
      }
    }
  }
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

a,
a:hover {
  color: #001822;
  text-decoration: none;
}

// website divider line
.delimiter-bottom,
.delimiter-top,
.img-bg img {
  position: relative;
}

.delimiter-top:before {
  top: 0;
}

.delimiter-bottom:after,
.delimiter-top:before {
  content: '';
  display: block;
  width: 80%;
  position: absolute;
  left: 50%;
  margin-left: -40%;
  height: 1px;
  background: radial-gradient(ellipse at center,
      rgba(0, 0, 0, 0.2) 0,
      hsla(0, 0%, 100%, 0) 75%);
}

// tongue
.tongue-bottom {
  top: auto;
  bottom: -1px;
  transform: translateX(-50%);
}

.tongue {
  display: inline-block;
  position: absolute;
  left: 50%;
  z-index: 1;
  transform: rotate(180deg) translateX(50%);
  width: 138px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #6c7686;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23fff' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
}

.btn {
  display: block;
  position: relative;
  line-height: 1.5;
  padding: 0.625rem 1.6rem;
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.toast-container.toast-top-center .ngx-toastr {
  margin-top: 5vh;
  max-width: 480px;
  width: unset !important;
}