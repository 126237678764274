.btn {
  font-size: 1rem;
  font-weight: 500;
  box-shadow: none;
  outline: none;
  border-radius: 0;
  text-align: center;
  line-height: 1;
  padding: 0.875rem 1rem;

  &.btn-small {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1;
    padding: 0.5rem 0.75rem;
  }

  &.btn-primary {
    color: var(--neutral-100);
    background-color: var(--primary-900);
    border: 1px solid transparent;
  }

  &.btn-primary:hover,
  &.btn-primary:focus,
  &.btn-primary:active {
    background-color: var(--neutral-800);
  }

  &.btn-primary:disabled {
    background-color: var(--neutral-100);
    color: var(--neutral-300);
  }

  &.btn-secondary {
    color: var(--primary-900);
    background-color: white;
    border: 0.4px solid var(--primary-900);
  }

  &.btn-secondary:hover,
  &.btn-secondary:focus,
  &.btn-secondary:active {
    background-color: var(--neutral-100);
  }

  &.btn-secondary:disabled {
    background-color: white;
    color: var(--neutral-300);
    border: 0.4px solid var(--neutral-300);
  }

  &.btn-tertiary {
    color: var(--primary-900);
    background-color: var(--primary-400);
  }

  &.btn-tertiary:hover,
  &.btn-tertiary:focus,
  &.btn-tertiary:active {
    background-color: var(--primary-200);
  }

  &.btn-tertiary:disabled {
    background-color: var(--neutral-100);
    color: var(--neutral-300);
    border: 1px solid transparent;
  }

  &.btn-danger {
    color: white;
    background-color: var(--danger);
  }

  &.btn-danger:hover,
  &.btn-danger:focus,
  &.btn-danger:active {
    opacity: 0.7;
  }

  &.btn-danger:disabled {
    background-color: var(--neutral-100);
    color: var(--neutral-300);
    border: 1px solid transparent;
  }

  &.btn-primary-text {
    color: var(--primary-900);
  }

  &.btn-primary-text:hover,
  &.btn-primary-text:focus,
  &.btn-primary-text:active {
    background-color: var(--secondary-400);
  }

  &.btn-primary-text:disabled {
    color: var(--secondary-500);
    border: 1px solid transparent;
  }

  &.btn-danger-text {
    color: var(--danger);
  }

  &.btn-danger-text:hover,
  &.btn-danger-text:focus,
  &.btn-danger-text:active {
    background-color: var(--danger-10);
  }

  &.btn-danger-text:disabled {
    color: var(--neutral-300);
    border: 1px solid transparent;
  }

  &.btn-primary-no-padding {
    color: var(--primary-900);
  }

  &.btn-primary-no-padding:hover,
  &.btn-primary-no-padding:focus,
  &.btn-primary-no-padding:active {
    color: var(--neutral-600);
  }

  &.btn-primary-no-padding:disabled {
    color: var(--neutral-300);
    border: 1px solid transparent;
  }

  &.btn-danger-no-padding {
    color: var(--danger);
  }

  &.btn-danger-no-padding:hover,
  &.btn-danger-no-padding:focus,
  &.btn-danger-no-padding:active {
    color: var(--danger);
    opacity: 0.7;
  }

  &.btn-danger-no-padding:disabled {
    color: var(--neutral-300);
    border: 1px solid transparent;
  }

  // loading
  .fa-spin {
    --fa-primary-color: var(--secondary-400);
    --fa-secondary-color: var(--neutral-300);
    margin-right: 0.25rem;
  }

  // icons
  & > .left-icon {
    margin-right: 0.5rem;
    margin-left: 0.125rem;

    &::before {
      font-size: 1rem;
    }
  }

  & > .right-icon {
    margin-left: 0.5rem;
    margin-right: 0.125rem;

    &::before {
      font-size: 1rem;
    }
  }

  .btn-icon-left {
    margin-right: 0.5rem;
    display: inline-block;
  }

  .btn-icon-right {
    order: 1;
    display: inline-block;
    margin-left: 0.5rem;
  }

  .fa-spinner {
    line-height: inherit;
  }
}

.btn-group {
  background-color: white;
  label {
    line-height: 2.25rem;
  }
}

// this is for a tab-like component
// should be deleted once a tab component is made

.btn-check:checked + .btn-secondary {
  background-color: var(--neutral-100);
}
