.back {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  width: auto;

  @include media-breakpoint-down(lg) {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba($black, 0.5);
      z-index: -1;
    }
  }

  &.back-static {
    @include media-breakpoint-down(lg) {
      position: static;
      height: 50vh;

      &::after {
        display: none;
      }
    }
  }
}
