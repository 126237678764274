.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 1.5rem;
  margin: 0;
}

.toggle-switch input:checked + .toggle-switch-slider {
  border: 0.0625rem solid #004f6e;
}

.toggle-switch-slider.rounded-circle {
  border-radius: 34px !important;
}

.toggle-switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0.0625rem solid #ced4da;
  border-radius: 0.2rem;
  background-color: transparent;
  transition: 0.4s;
}

.rounded-circle {
  border-radius: 50% !important;
}

.toggle-switch input:checked + .toggle-switch-slider:before {
  transform: translateX(1.625rem);
}

.toggle-switch input:checked + .toggle-switch-slider:before {
  background-color: #004f6e;
  border-color: #004f6e;
}

.toggle-switch input {
  display: none;
}

.toggle-switch-slider.rounded-circle:before {
  border-radius: 50% !important;
}

.toggle-switch-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 0.0625rem;
  bottom: 2px;
  border-radius: 0.2rem;
  background-color: #ced4da;
  transition: 0.3s;
}
